import { Component, OnInit, Attribute } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuProService } from '../menu-pro.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { QrCodesProService } from '../qr-codes-pro.service';
import { ToastrService } from 'ngx-toastr';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatDialog } from '@angular/material/dialog';
import { RatePopupComponent } from '../rate-popup/rate-popup.component';
import { CarlosPopupComponent } from '../carlos-popup/carlos-popup.component';


@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit {
  res: any;
  id: any;
  back: any = false;
  menu: any;
  isPdf: any;
  facebook: any;
  instagram: any;
  website: any;
  survey: any;
  service: any;
  file_path: any;
  logo_path: any;
  background_path: any;
  type: any;
  qrButtons: any[] = [];
  design_type: any;

  url: any;
  show_f: boolean = false;
  questions: any[] = [];
  questionAnswers: any[] = [];
  surveyAnswers: Map<any,any> = new Map<any,any>();
  finalAnswersObj: any = {
    user_phone: null,
    user_email: null,
    qrcode_id: null,
    answers: [],
  };

  staticQuestion1 = { type: 'text', title: 'What is your Name' };
  staticQuestion2 = { type: 'text', title: 'What is your phone number' };

  deviceInfo: any;
  device_id: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private spinner: NgxSpinnerService,
    private qrCodeService: QrCodesProService,
    private toast: ToastrService,
    public dialog: MatDialog,
    private menuPro: MenuProService
  ) {
    this.route.queryParams.subscribe((params) => {
      if (params.back) {
        this.back = params.back;
      }
    });
    this.route.params.subscribe((params) => {
      this.id = params.id;
      if (this.id) {
        let d_id = localStorage.getItem('device_id');
        if (d_id) {
          this.epicFunction(this.id, JSON.parse(d_id));
        } else {
          let d_id = Math.random() * 10000000000000000;
          localStorage.setItem('device_id', JSON.stringify(d_id));
          this.epicFunction(this.id, d_id);
        }
      } else {
        this.router.navigate(['/']);
      }
    });
  }

  ngOnInit(): void {
    // this.getLocation();

    document.addEventListener('scroll', (event) => {
      let url = window.location.href;
      if (url.includes('/qrcode/')) {
        if (this.type == 'cumulative') {
          let div = document.getElementById('top');
          let rect = div.getBoundingClientRect();
          let t = rect.top;
          let h = rect.height;
          let s = h + t;

          if (s < -20) {
            this.show_f = true;
          } else {
            this.show_f = false;
          }
        }
      }
    });
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const longitude = position.coords.longitude;
        const latitude = position.coords.latitude;
        console.log(longitude, latitude);
      });
    } else {
      console.log('No support for geolocation');
    }
  }

  epicFunction(id, d_id) {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    console.log(this.deviceInfo);
    let d = JSON.stringify(this.deviceInfo);
    this.getMenu(id, d, d_id);
  }

  getMenu(id, device_info, device_id) {
    this.spinner.show();
    this.menuPro.getMenu(id, device_info, device_id, true).subscribe(
      (res: any) => {
        this.spinner.hide();
        console.log(res);
        this.res = res;
        this.type = res.qrcode.type;
        this.design_type = res.qrcode.design_type;
        if (res.qrcode.type === 'survey') {
          this.logo_path = res.qrcode.logo_path;
          this.questions = res.qrcode.survey_attributes;
          this.menu = res.qrcode;
          for (let index = 0; index < this.menu.attributes.length; index++) {
            if (this.menu.attributes[index].key == 'facebook') {
              this.facebook = this.menu.attributes[index].value;
            } else if (this.menu.attributes[index].key == 'instagram') {
              this.instagram = this.menu.attributes[index].value;
            } else if (this.menu.attributes[index].key == 'website') {
              this.website = this.menu.attributes[index].value;
            } else if (this.menu.attributes[index].key == 'service') {
              this.service = this.menu.attributes[index].value;
            }
          }
          this.questions.forEach((element) => {
            if (element.type == 'radio' || element.type == 'checkboxes') {
              element.answers = element.answers.split('@');
            }
          });
          if (this.id != 232) {
            this.questions.unshift(this.staticQuestion2);
            this.questions.unshift(this.staticQuestion1);
          }
          this.questionAnswers = new Array(this.questions.length)
            .fill({ question: null, answer: null, survey_attribute_id: null })
            .map((e, i) => {
              return {
                question: this.questions[i].title,
                answer: '',
                survey_attribute_id: null,
              };
            });
          console.log(this.questionAnswers);

          this.finalAnswersObj.qrcode_id = this.id;
          this.background_path = res.qrcode.background_image_path;
        } else if (res.qrcode.type == 'template') {
          this.menu = res.qrcode;
          this.logo_path = this.menu.logo_path;
          for (let index = 0; index < this.menu.attributes.length; index++) {
            if (this.menu.attributes[index].key == 'facebook') {
              this.facebook = this.menu.attributes[index].value;
            } else if (this.menu.attributes[index].key == 'instagram') {
              this.instagram = this.menu.attributes[index].value;
            } else if (this.menu.attributes[index].key == 'website') {
              this.website = this.menu.attributes[index].value;
            } else if (this.menu.attributes[index].key == 'survey') {
              this.survey = this.menu.attributes[index].value;
            } else if (this.menu.attributes[index].key == 'service') {
              this.service = this.menu.attributes[index].value;
            } else if (this.menu.attributes[index].key == 'file_path') {
              this.file_path = this.menu.attributes[index].value;
              this.isPdf = this.menu.attributes[index].ext;
              if (this.isPdf != 'pdf') {
                document.getElementById('spin').classList.add('hide_spin');
              }
            }
          }
          const rating_exceptions = [2331, 2245, 2247, 2248, 2332, 2333, 2180, 2491, 2490, 2489, 2488, 2487, 2486, 2523, 2520, 2521, 2522];
          if (!rating_exceptions.includes(res.qrcode.id)) {
            setTimeout(() => {
              this.openRatePopup();
            }, 40000);
          }

        } else if (res.qrcode.type == 'cumulative') {
          this.menu = res.qrcode;
          this.logo_path = this.menu.logo_path;
          this.background_path = this.menu.background_image_path;
          for (let index = 0; index < this.menu.attributes.length; index++) {
            if (this.menu.attributes[index].key == 'Links') {
              let linksArr = JSON.parse(this.menu.attributes[index].value);
              linksArr.forEach((element) => {
                if (element.key == 'facebook') {
                  this.facebook = element.value;
                } else if (element.key == 'instagram') {
                  this.instagram = element.value;
                } else if (element.key == 'website') {
                  this.website = element.value;
                } else if (element.key == 'survey') {
                  this.survey = element.value;
                }
              });
            } else if (this.menu.attributes[index].key == 'Qrcodes') {
              this.qrButtons = JSON.parse(this.menu.attributes[index].value);
              document.getElementById('spin').classList.add('hide_spin');
            } else if (this.menu.attributes[index].key == 'file_path') {
              this.file_path = this.menu.attributes[index].value;
              this.isPdf = this.menu.attributes[index].ext;
              if (this.isPdf != 'pdf') {
                document.getElementById('spin').classList.add('hide_spin');
              }
            }
          }
          if (res.qrcode.id == 1912 || res.qrcode.id == 1885 || res.qrcode.id == 1893){
            this.openCarlosPopup();
          }
        } else {
          location.replace(this.getLink(res.qrcode.link));
        }
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
        this.toast.error(err.error.Message, '', {
          timeOut: 3000,
        });
        document.getElementById('spin').classList.add('hide_spin');
      }
    );
  }

  getLink(link) {
    if (link.includes('http')) {
      return link;
    } else {
      return `//${link}`;
    }
  }

  show(id, id2) {
    document.getElementById(id).classList.toggle('show');
    document.getElementById(id2).classList.toggle('show');
  }

  callBackFn(pdf: PDFDocumentProxy) {
    // this.spinner.hide();
    document.getElementById('spin').classList.add('hide_spin');
  }

  goBack() {
    window.history.back();
  }

  /* Survey Answers */

  getAnswers(answer, ques_index, id) {
    if (ques_index == 0) {
      this.finalAnswersObj.user_email = answer;
    } else if (ques_index == 1) {
      this.finalAnswersObj.user_phone = answer;
    } else {
      // this.questionAnswers[ques_index].question = question;
      this.questionAnswers[ques_index].answer = answer;
      this.questionAnswers[ques_index].survey_attribute_id = id;
    }
    console.log(this.questionAnswers);
  }

  getCheckboxesAnswers(event, answer, ques_index, id, ans_index) {
    console.log(event.currentTarget.checked);
    if (ques_index == 0) {
      this.finalAnswersObj.user_email = answer;
    } else if (ques_index == 1) {
      this.finalAnswersObj.user_phone = answer;
    } else {
      // this.questionAnswers[ques_index].question = question;
      if (event.currentTarget.checked == true) {
        this.surveyAnswers.set(ans_index, answer);
      } else if (event.currentTarget.checked == false) {
        this.surveyAnswers.delete(ans_index);
      }

      var tempArr = [];
      for (let [key, value] of this.surveyAnswers) {
        console.log(key, value);
        tempArr.push(value);
    }
      this.questionAnswers[ques_index].answer = tempArr.toString();
      this.questionAnswers[ques_index].survey_attribute_id = id;
    }
    console.log(this.surveyAnswers);
  }

  submitSurvey() {
    this.questionAnswers.splice(0, 2);
    this.finalAnswersObj.answers = this.questionAnswers;
    console.log(this.finalAnswersObj);
    // if (!this.finalAnswersObj.user_email) {
    //   this.toast.error('Please Fill All Fields!', '', {
    //     timeOut: 3000
    //   });
    //   return false;
    // } else if (!this.finalAnswersObj.user_phone) {
    //   this.toast.error('Please Fill All Fields!', '', {
    //     timeOut: 3000
    //   });
    //   return false;
    // } else {
    //   for (let index = 0; index < this.finalAnswersObj.answers.length; index++) {
    //     const element = this.finalAnswersObj.answers[index];
    //     if (!element.answer) {
    //       this.toast.error('Please Fill All Fields!', '', {
    //         timeOut: 3000
    //       });
    //       return false;
    //     }
    //   }
    // }

    this.spinner.show();
    this.qrCodeService.submitSurvey(this.finalAnswersObj).subscribe(
      (res) => {
        this.spinner.hide();
        this.toast.success('Thanks for your opinion :)', '', {
          timeOut: 3000,
        });
        setTimeout(() => {
          window.history.back();
        }, 3000);
      },
      (err) => {
        this.spinner.hide();
        this.toast.error('Something went wrong!', '', {
          timeOut: 3000,
        });
        console.log(err);
      }
    );
  }

  openRatePopup() {
    let w = window.innerWidth;
    if (w > 600) {
      const dialogRef = this.dialog.open(RatePopupComponent, {
        width: '400px',
        // height: '80vh',
        panelClass: 'sliP',
        data: { id: this.id },
      });
    } else {
      const dialogRef = this.dialog.open(RatePopupComponent, {
        width: '90%',
        // height: '40vh',
        panelClass: 'sliP',
        data: { id: this.id },
      });
    }
  }
  openCarlosPopup() {
    let w = window.innerWidth;
    if (w > 600) {
      const dialogRef = this.dialog.open(CarlosPopupComponent, {
        width: '400px',
        // height: '80vh',
        panelClass: 'sliP',
        data: { id: this.id },
      });
    } else {
      const dialogRef = this.dialog.open(CarlosPopupComponent, {
        width: '90%',
        // height: '40vh',
        panelClass: 'sliP',
        data: { id: this.id },
      });
    }
  }
}
